export const menuItems = [
  {
    name: 'Fesztiválok',
    link: '/fesztivalok',
  },
  {
    name: 'Zenekarok',
    link: '/zenekarok',
  },
  {
    name: 'Oktatás',
    link: '/oktatas',
  },
];
