// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fesztivalok-js": () => import("./../../../src/pages/fesztivalok.js" /* webpackChunkName: "component---src-pages-fesztivalok-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oktatas-js": () => import("./../../../src/pages/oktatas.js" /* webpackChunkName: "component---src-pages-oktatas-js" */),
  "component---src-pages-zenekarok-js": () => import("./../../../src/pages/zenekarok.js" /* webpackChunkName: "component---src-pages-zenekarok-js" */)
}

